import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { useTranslation } from 'react-i18next';
import { useApolloClient, useMutation } from '@apollo/client';
import { connect, useDispatch } from 'react-redux';
import { usePathname } from 'next/navigation';
import * as actionCreators from '../../actions';
import {
  togglePromotionModalView,
  toggleReplenishmentNotificationsModal
} from '../../actions';
import { sendAccessToken } from '../../queries/mutations';
import { readNotificationQuery } from '../../queries/mutations/readNotification';
import { getLangDefinition } from '../../utils/helpers';
import AdblockerDetector from '../Banner/BlockerDetector';
import HeaderPlaceholder from '../Placeholders/HeaderPlaceholder';
import Loader from '../Loader';
import { useBridgeState } from '../../bridge';
import useBackHistoryStorage from '../../hooks/useBackHistoryStorage';
import useScrollState from '../../hooks/useScrollState';
import Icon from '../Icon';
import Logo from '../../public/static/images/brown-logo.svg';
import Plus from '../../public/static/images/green-plus.svg';
import {
  longPromotionHashes,
  shortPromotionHashes,
  urlHashes
} from '../../utils/helpers/constants';
import CloseWebview from '../../public/static/images/close-webview.svg';
import useScreenSize from '../../hooks/useScreenSize';
import { useShowFlexibleComponents } from '../../hooks/useShowFlexibleComponents';
import { useAutoScrollTop } from '../../hooks/useAutoScrollTop';
import { WelcomeBanner } from '../ReplenishmentWelcomeBanner';
import { useTargetedBanner } from '../../hooks/useTargetedBanner';
import { TargetedBannerIds } from '../../hooks/useTargetedBanner/types';
import Footer from '../Footer';
import { TabBarNavigation } from '../TabBarNavigation';
import MainLayout from '../ui/Layout';
import { InitialFlexibleHeader } from '../FlexibleHeader/InitialFlexibleHeader';
import { HeaderWrapperBlock } from './styled';

const BannersContainer = dynamic(() => import('../Banner/BannersContainer'), {
  ssr: false
});

const FlexibleHeader = dynamic(
  () => import('../FlexibleHeader').then(mod => mod.FlexibleHeader),
  { ssr: false, loading: InitialFlexibleHeader }
);

const HeaderLight = isWebView => {
  return (
    <div className="white-header white-header--main" id="white-header">
      <a data-stat="header-logo" href="/" tabIndex="-1">
        <Icon IconComponent={Logo} width={85} height={19} />
      </a>
      {isWebView ? (
        <button className="white-header__webview-btn">
          <i>
            <Icon
              IconComponent={CloseWebview}
              width={15}
              height={15}
              indent={false}
              color={'#a9a39e'}
            />
          </i>
        </button>
      ) : (
        <a
          className="white-header__new-btn"
          href="/items/new"
          data-stat="header-new-btn"
        >
          <Icon IconComponent={Plus} width={30} height={30} indent={false} />
        </a>
      )}
    </div>
  );
};

const PromotionModalView = dynamic(() => import('../PromotionModalView'), {
  ssr: false
});

const SideMenuModal = dynamic(() => import('../SideMenuModal'), {
  ssr: false
});

const AuthModal = dynamic(() => import('../AuthModal'), {
  ssr: false
});

let isLoading = null;
const mapStateToProps = state => {
  return {
    isVisible: state.drawerUI.isVisible,
    isSideMenuVisible: state.sideMenuModal?.isVisible,
    isPromotionModalView: state.promotionModalView?.isVisible,
    isAuthVisible: state?.authModal?.isVisible,
    replenishmentNotifications: state?.replenishmentNotifications,
    showNotificationsModal: state?.replenishmentNotificationsModal?.isVisible
  };
};

const Layout = ({
  isBanner,
  children,
  noBorder,
  setBlockerDetector,
  toggleSideMenuModal,
  isAuthVisible,
  isSideMenuVisible,
  isPromotionModalView,
  toggleAuthModal,
  headerTitle,
  isBottomNavigation = true,
  isShowHeader = true,
  isFixedHeader = false,
  isShowBackButton = false,
  isShowCloseButton = true,
  isCurrentItemPage = false,
  isHomePage = false,
  isSafeAreaTop = false,
  replenishmentNotifications,
  showNotificationsModal,
  isBackgroundBanner = false,
  pageProps = {}
}) => {
  const { apolloCache, regions, citiesUnPopularList } = pageProps;
  const client = useApolloClient();

  const {
    i18n: { language }
  } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();
  const { isWebView } = useBridgeState();
  const { isScroll } = useScrollState();
  const { isSmall } = useScreenSize();
  const { isShowFlexibleComponents } = useShowFlexibleComponents();
  const pathname = usePathname();
  const hasAuthHash = router.asPath.includes(urlHashes.authentication);

  const isSearchPage = router.pathname === '/[...search]';

  useTargetedBanner(TargetedBannerIds.catfish);

  useEffect(() => {
    if (isSafeAreaTop && isWebView) {
      document.body.classList.add('safe-area-top');
    } else {
      document.body.classList.remove('safe-area-top');
    }
  }, [isSafeAreaTop, isWebView]);

  useEffect(() => {
    if (apolloCache) client.cache.restore(apolloCache);
  }, [client, apolloCache]);

  const closeAuthModalHandler = () => {
    toggleAuthModal(false);

    router.push(`${router.asPath.split('#')[0]}`, undefined, {
      shallow: true
    });
  };

  const accessToken = router?.query.access_token;
  const [isNotificationShow] = useMutation(readNotificationQuery);
  const [mutateToken] = useMutation(sendAccessToken, {
    onCompleted: () => {
      isLoading = false;
    }
  });

  useBackHistoryStorage();

  useAutoScrollTop(isCurrentItemPage, pathname);

  useEffect(() => {
    if (router?.isReady) {
      if (accessToken) {
        isLoading = true;
        mutateToken({
          variables: { accessToken }
        });
      } else {
        isLoading = false;
      }
    }
  }, [accessToken]);

  useEffect(() => {
    toggleAuthModal(!!router.asPath.match(urlHashes.authentication));
    toggleSideMenuModal(!!router.asPath.match(urlHashes.more));

    dispatch(
      togglePromotionModalView(
        shortPromotionHashes.some(hash => router.asPath.match(hash))
      )
    );

    router?.events.on('hashChangeComplete', url => {
      toggleAuthModal(!!url.match(urlHashes.authentication));
      toggleSideMenuModal(!!url.match(urlHashes.more));

      dispatch(
        togglePromotionModalView(
          !!longPromotionHashes.some(hash => url.match(hash))
        )
      );
    });
  }, []);

  const WhiteHeader = dynamic(() => import('../WhiteHeader'), {
    loading: isHomePage ? HeaderLight.bind(this, isWebView) : HeaderPlaceholder,
    ssr: false
  });

  const isAuthInProgress =
    (!router?.isReady && isLoading === null && !accessToken) ||
    (accessToken && isLoading !== false);

  const getHeader = () => {
    if (isShowFlexibleComponents)
      return (
        <FlexibleHeader
          {...{
            isFixedHeader: isWebView || isFixedHeader,
            isShowCloseButton,
            isAuthInProgress
          }}
        />
      );

    return (
      <WhiteHeader
        {...{
          noBorder,
          headerTitle,
          isFixedHeader: isWebView || isFixedHeader,
          isShowBackButton,
          isShowCloseButton
        }}
      />
    );
  };

  const closeNotificationsModal = () => {
    dispatch(toggleReplenishmentNotificationsModal(false));

    isNotificationShow({
      variables: { id: replenishmentNotifications[0]?.id }
    });
  };

  return (
    <MainLayout
      language={getLangDefinition(language)}
      {...{
        isScroll,
        isWebView
      }}
    >
      {replenishmentNotifications?.length > 0 && (
        <WelcomeBanner
          isOpen={showNotificationsModal}
          hide={closeNotificationsModal}
          id={replenishmentNotifications[0]?.id}
          description={replenishmentNotifications[0]?.description}
          title={replenishmentNotifications[0]?.title}
        />
      )}
      <BannersContainer {...{ isBackgroundBanner, isBanner }} />
      {isShowHeader && (
        <HeaderWrapperBlock $isSearchPage={isSearchPage}>
          {getHeader()}
        </HeaderWrapperBlock>
      )}
      <AdblockerDetector setBlockerDetector={setBlockerDetector} />
      {children}
      <Loader pageLoader={true} />
      {isBottomNavigation && (
        <TabBarNavigation isAuthInProgress={isAuthInProgress} />
      )}
      {!(isWebView || isSideMenuVisible) && (
        <Footer
          footerData={{ regions, citiesUnPopularList }}
          isTabBarExist={isBottomNavigation}
          isBackgroundBanner={isBackgroundBanner && !isSmall}
        />
      )}
      {isSideMenuVisible && <SideMenuModal />}
      {isPromotionModalView && <PromotionModalView />}
      {isAuthVisible && hasAuthHash && (
        <AuthModal
          isOpen={isAuthVisible}
          modalId="auth"
          closeHandler={closeAuthModalHandler}
        />
      )}
    </MainLayout>
  );
};

Layout.propTypes = {
  noBorder: PropTypes.bool,
  children: PropTypes.node,
  toggleDrawer: PropTypes.func,
  toggleAuthModal: PropTypes.func,
  isVisible: PropTypes.bool,
  isBanner: PropTypes.bool,
  isAuthVisible: PropTypes.bool,
  isSideMenuVisible: PropTypes.bool,
  isPromotionModalView: PropTypes.bool,
  setBlockerDetector: PropTypes.func,
  toggleSideMenuModal: PropTypes.func,
  isBottomNavigation: PropTypes.bool,
  isShowHeader: PropTypes.bool,
  isFixedHeader: PropTypes.bool,
  headerTitle: PropTypes.string,
  isShowBackButton: PropTypes.bool,
  isShowCloseButton: PropTypes.bool,
  isCurrentItemPage: PropTypes.bool,
  isHomePage: PropTypes.bool,
  isSafeAreaTop: PropTypes.bool,
  replenishmentNotifications: PropTypes.array,
  showNotificationsModal: PropTypes.bool,
  toggleNotificationsModal: PropTypes.func,
  isBackgroundBanner: PropTypes.bool,
  pageProps: PropTypes.object
};

export default connect(mapStateToProps, actionCreators)(Layout);
